'use client';

import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import moment from 'moment/moment';
import styles from './DefaultBanner.module.scss';
import HtmlMarkup from '@app/components/HtmlMarkup/HtmlMarkup';
import CountDownTimer from '@app/ui/CountDownTimer/CountDownTimer';
import Image from '@app/components/Image/Image';
import { IBannerApiModel } from '@app/models/sections/banner';
import PdfButton from '@app/components/PdfButton/PdfButton';

/**
 * Компонент дефолтного баннера
 */
export default function DefaultBanner({
    background,
    image,
    timer,
    title,
    button,
}: IBannerApiModel) {
    const then = moment.utc(timer?.date); // Event final Date
    const now = moment.utc();
    const isTimer = timer?.date && now.isBefore(then);

    return (
        <div
            className={styles['banner']}
            style={{ backgroundImage: `url(${background})` }}
        >
            <div className={styles['content']}>
                <HtmlMarkup
                    tag="h2"
                    className={buildClassNames(styles, [
                        'title',
                        !image && 'title_without-image',
                    ])}
                    markup={title}
                />
                {isTimer && (
                    <CountDownTimer {...timer} />
                )}
                {image && (
                    <div
                        className={buildClassNames(styles, [
                            'image-container',
                            isTimer && 'image-container__hide',
                        ])}
                    >
                        <Image
                            className={styles['image']}
                            src={image}
                            fill
                            alt=""
                            quality={100}
                        />
                    </div>
                )}
                {button?.text && (
                    <PdfButton
                        {...button}
                        containerClassName={styles['button-container']}
                        className={styles['button']}
                        textClassName={styles['button-text']}
                    />
                )}
            </div>
        </div>
    );
}
